import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

// Custom components

import axios from "axios";
import { useHistory } from "react-router-dom";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";

// Google Login
import { GoogleLogin } from "react-google-login";
import { useState } from "react";
import { getApi } from "utils/requests";
import { request } from "utils/axiosRequest";

function SignUp() {
  // Chakra color mode
  const history = useHistory();
  const handleClick = () => setShow(!show);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [user, setUser] = useState({
    name: localStorage.getItem("name"),
    company: "",
    designation: "",
  });
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const onClick = async (e) => {
    const serverResponse = await request({
      method: "PUT",
      url: getApi("api/user"),
      data: user,
    });

    if (serverResponse.data.success) {
      history.replace("/admin");
    } else {
      alert("Something went wrong");
    }
  };

  const [show, setShow] = React.useState(false);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh", sm: "20vh" }}
        flexDirection="column">
        <FormControl w={{ base: "20vw", sm: "100%", xs: "100%" }}>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px">
            Full Name<Text color={brandStars}></Text>
          </FormLabel>
          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            defaultValue={user.name}
            name="name"
            onChannge={onChange}
            placeholder={localStorage.getItem("name")}
            mb="12px"
            width={"100%"}
            fontWeight="500"
            size="lg"
          />
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px">
            Company Name<Text color={brandStars}></Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            onChange={onChange}
            name="company"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Pillup"
            mb="12px"
            fontWeight="500"
            size="lg"
          />

          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px">
            Designation<Text color={brandStars}></Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            onChange={onChange}
            name="designation"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Founder"
            mb="12px"
            fontWeight="500"
            size="lg"
          />

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            onClick={onClick}
            mt={"10px"}
            mb="24px">
            Sign Up
          </Button>
        </FormControl>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
