// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React from "react";
import { generateDiscontinuationChart } from "variables/configureCharts";
import GetDashBoardStatistics from "hooks/GetDashBoardStatistics";

export default function Conversion(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const colors = ["#254dff", "#ff9900", "#ff3e25", "#ff1981"];
  const { data: DashboardStats } = GetDashBoardStatistics();
  return (
    <Card
      p="20px"
      align="center"
      direction="column"
      w={{
        base: "100vw",
        lg: "100%",
      }}
      h="100%"
      {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="start"
        w="100%"
        h={"100%"}
        direction={"column"}
        mb="8px">
        <Text
          me="auto"
          mb={"10px"}
          textAlign="left"
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="25px">
          Reason for Discontinuation [Last Updated: 1st October]
        </Text>
      </Flex>
      <>
        {props && props?.discontinuationChart && (
          <>
            <PieChart
              chartData={
                generateDiscontinuationChart(props?.discontinuationChart)
                  .DiscontinuationChart
              }
              chartOptions={
                generateDiscontinuationChart(props?.discontinuationChart)
                  .DiscontinuationChartOptions
              }
            />
          </>
        )}
      </>
      <Flex justifyContent={"center"}>
        {props && props?.discontinuationChart && (
          <>
            {props?.discontinuationChart?.headers.map((item, index) => (
              <Flex alignItems={"center"} gap="10px">
                <Box w={"15px"} h={"15px"} bg={colors[index]}></Box>
                <p style={{ fontSize: "12px", marginRight: "10px" }}>{item}</p>
              </Flex>
            ))}
          </>
        )}
      </Flex>
    </Card>
  );
}
