import Cookies from "js-cookie";
import { getBaseURL } from "./baseUrl";

// a function to store the token in the cookie
const signIn = (token, newUser = 0, user) => {
  Cookies.set("jwt", token, { expires: 365, secure: true });
  localStorage.setItem("isLoggedIn", "true");

  window._mfq = window._mfq || [];
  window._mfq.push(["setVariable", "email", user.email]);


  if (newUser === 1) {
    localStorage.setItem("newUser", "true");
  }
};

const checkStatusCode = (response) => {
  if (response.status === 401) {
    signOut();
    window.location.href = "/";
  }
  return response;
}

// a function to sign out the user
const signOut = () => {
  Cookies.remove("jwt");
  localStorage.removeItem("isLoggedIn", "false");
  localStorage.removeItem("newUser", "false");
  localStorage.removeItem("image");
};

// a function to check if the user is authenticated
const isAuthenticated = () => {
  const token = Cookies.get("jwt");
  if (localStorage.getItem("isLoggedIn") === "true") {
    if (token && token !== "undefined" && token !== "null") {
      return true;
    }
    return false;
  } else {
    return false;
  }
};

const getApi = (url) => {
  return `${getBaseURL("PROD")}/${url}`;
};

export { signIn, signOut, isAuthenticated, getApi };
