// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import BarChart from "components/charts/BarChart";
import React from "react";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
import { MdBarChart } from "react-icons/md";
import { customerAcquisitionChart } from "variables/configureCharts";
import { BiInfoCircle } from "react-icons/bi";

export default function WeeklyRevenue (props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const colors = ["#fe4b9c", "#5df673", "#3e63f2"];
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  return (
    <Card
      align="center"
      direction="column"
      w={{
        base: "100vw",
        lg: "100%",
      }}
      {...rest}
      h="100%">
      <Flex align="center" w="100%" px="15px" py="10px" justifyContent="center">
        <Text
          me="auto"
          mb={"10px"}
          color={textColor}
          fontSize="lg"
          textAlign={"left"}
          fontWeight="700"
          lineHeight="100%">
          New Customer Acquisition Breakdown
        </Text>
        <Tooltip
          bg={"#0e1014"}
          shadow="md"
          textColor={"white"}
          padding="10px 20px"
          label={
            <Box w="100%" h="100%" display="flex" flexDirection="column">
              <Text>Partnership : Doctor Partnership, Old Age Homes</Text>
              <Text>
                Referral : Referral from Pillup Team, existing customers
              </Text>
              <Text>Marketing : Offline & Online Campaigns</Text>
            </Box>
          }
          hasArrow>
          <span>
            {<Icon w="32px" h="32px" as={BiInfoCircle} color={"#3b57cc"} />}
          </span>
        </Tooltip>
      </Flex>
      {props && props.customerAcquisitionChart && (
        <>
          <Box h="280px" mt="auto">
            <BarChart
              chartData={[
                ...customerAcquisitionChart(props?.customerAcquisitionChart)
                  .CustomerAcquisitionChart,
              ]}
              chartOptions={
                customerAcquisitionChart(props?.customerAcquisitionChart)
                  .CustomerAcquisitionChartOptions
              }
            />
          </Box>
          <Flex justifyContent={"center"} gap="15px">
            {props?.customerAcquisitionChart?.name.map((item, index) => (
              <Flex alignItems={"center"} gap="10px">
                <Box w={"15px"} h={"15px"} bg={colors[index]}></Box>
                <h1>{item}</h1>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </Card>
  );
}
