import { useQuery } from "react-query";
import axios from "axios";

export default function GetOrders() {
  let apiResponse = useQuery("order-stats", () =>
    axios({
      // url: `${baseUrl}/orders?_sort=id&_order=desc&deletedAt&_expand=user&_expand=prescription&_expand=patient&oldOrder=false&_expand=address`,
      url: "http://localhost:3005/orders?token=let!tb3e&deletedAt&_expand=prescription",
      method: "GET",
    })
  );
  return apiResponse;
}
