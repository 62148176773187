import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
// import RTLLayout from "layouts/rtl";
import { ReactQueryDevtools } from "react-query/devtools";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { PrivateRoute } from "routes/privateRoutes";
import SignUp from "views/auth/signup";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { signOut } from "utils/requests";
// import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess: (error, query) => {
      
     if(error.response.status === 401){
      
       window.location.href = "/auth/sign-in";
       signOut();
     }
    },
  }),
});
ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <PrivateRoute path={`/new`} component={SignUp} />
            <PrivateRoute path={`/admin`} component={AdminLayout} />

            {/* <Route path={`/rtl`} component={RTLLayout} /> */}

            <Redirect from="/" to="/auth" />
          </Switch>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
