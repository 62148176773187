import axios from "axios";
import Cookies from "js-cookie";
import { getBaseURL } from "./baseUrl";

const client = axios.create({ baseURL: getBaseURL("PROD") });
export const request = ({ ...options }) => {
    client.defaults.headers.common["Authorization"] = Cookies.get("jwt");
    const onSuccess = (response) => {
        return response;
    }
    const onError = (error) => {
        return error;
    }
    return client(options).then(onSuccess).catch(onError);
}