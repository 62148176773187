// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";
import { BiInfoCircle } from "react-icons/bi";

export default function Default(props) {
  const { startContent, name, growth, value, message } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card py="15px">
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "center" }}
        justify={{ base: "center", xl: "center" }}>
        {startContent}

        <Stat my="auto" ms={startContent ? "18px" : "0px"}>
          <StatLabel
            lineHeight="20px"
            color={textColorSecondary}
            fontSize={{
              base: "md",
            }}>
            {name}
          </StatLabel>
          <StatNumber
            color={textColor}
            fontSize={{
              base: "2xl",
            }}>
            {value}
          </StatNumber>
          {growth ? (
            <Flex align="center">
              <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                {growth}
              </Text>
              <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                since last month
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms="auto" w="max-content">
          <Tooltip
            bg={"#0e1014"}
            shadow="md"
            textColor={"white"}
            padding="10px 20px"
            label={message}
            hasArrow>
            <span>
              {<Icon w="32px" h="32px" as={BiInfoCircle} color={"#3b57cc"} />}
            </span>
          </Tooltip>
        </Flex>
      </Flex>
    </Card>
  );
}
