
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets

// Custom components

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { AiFillInfoCircle, AiFillMedicineBox } from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";
import { IoBag, IoHappySharp, IoTodaySharp } from "react-icons/io5";
import { HiOutlineStatusOffline, HiOutlineStatusOnline } from "react-icons/hi";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdDeliveryDining,
  MdFileCopy,
} from "react-icons/md";
import db from "scripts/db.json";
import DailyTraffic from "views/admin/default/components/DailyTraffic";

import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import OrderReorderStats from "./components/OrderReorderStats";
import PieChart from "components/charts/PieChart";
import Conversion from "./components/PieCard";
import GetDashBoardStatistics from "hooks/GetDashBoardStatistics";
import axios from "axios";
import GetOrders from "hooks/getOrders";

import { GetPRXTableDataCategorically } from "scripts/generateRows";
import { getOrdersNewOrders } from "scripts/sc";
import { Redirect } from "react-router-dom";
import { signOut } from "utils/requests";
export default function UserReports() {
  try {
    const brandColor = "#7ccc88";
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

    let medicineHash = new Map();
    if (db) {
      for (let i = 0; i < db?.medicines.length; i++) {
        if (medicineHash.has(db.medicines[i].id)) {
          continue;
        }
        medicineHash.set(db.medicines[i].id, db.medicines[i]);
      }
    }
    const getMedicineIds = (orders) => {
      // loop

      for (let i = 0; i < orders.length; i++) {
        let medicineIds = orders[i]?.prescription.medicines;
        let medicineObjectArray = [];
        for (let j = 0; j < medicineIds.length; j++) {
          if (medicineHash.get(medicineIds[j])) {
            medicineObjectArray.push(medicineHash.get(medicineIds[j]));
          }
        }

        orders[i].revenue =
          GetPRXTableDataCategorically(medicineObjectArray).total;
      }

      return getOrdersNewOrders(orders);
      // return orders;
    };

    const { data: chartsData, isError } = GetDashBoardStatistics();
    const { data: orders } = GetOrders();
    return (
      <>
        <Box pt={{ base: "50px", md: "80px", xl: "80px" }} mt={"5vh"}>
          <Text fontSize="2xl" fontWeight="bold" mb="5" pl={{ base: "20px" }}>
            Hey, {localStorage.getItem("name")}
          </Text>

          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
            gap="20px"
            mb="20px"
            pl={{ base: "20px", md: "0px" }}>
            {/* Total Revenue */}

            <MiniStatistics
              message="Total Revenue calculated from June to September"
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdAttachMoney}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Revenue"
              value={chartsData?.data?.data.totalRevenue}
            />

            <MiniStatistics
              message="Total Orders delivered from June to September"
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="28px"
                      h="28px"
                      as={IoTodaySharp}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Orders"
              value={chartsData?.data?.data.totalOrders}
            />

            <MiniStatistics
              message="Number of Medicine Saches delivered to the customers"
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Pouches Delivered"
              value={chartsData?.data?.data.totalPouchesDelivered}
            />
            {/* Average Days of Medicine */}
            <MiniStatistics
              message="Average Number of Pouches per Order"
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={IoHappySharp}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Pouches per Order"
              value={chartsData?.data?.data.averagePouchesPerOrder}
            />

            {/* Total Number of Orders */}
            <MiniStatistics
              message="Average Days of Medicine Delivered"
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="28px"
                      h="28px"
                      as={IoTodaySharp}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Average Days of Medicine"
              value={chartsData?.data?.data.averageDaysMedicineDelivered}
            />

            <MiniStatistics
              message="Total Days of Medicine Delivered calculated from June to September"
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="28px"
                      h="28px"
                      as={MdDeliveryDining}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Days of Medicine Delivered"
              value={chartsData?.data?.data.totalDaysMedicineDelivered}
            />

            <MiniStatistics
              message="Number of Users acquired from June to September"
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={FaUserFriends}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Number of Users"
              value={
                <Flex alignItems={"center"} gap="5px">
                  <HiOutlineStatusOnline color="#8eed9c" />
                  <p style={{ color: "#8eed9c" }}>
                    {chartsData?.data?.data.numberOfUsers[0]}
                  </p>
                  {/* |
              <HiOutlineStatusOffline color="#ffe46e" />
              <p style={{ color: "#ffe46e" }}>
                {chartsData?.data?.data.numberOfUsers[1]}
              </p> */}
                </Flex>
              }
            />
            <MiniStatistics
              message="Average Number of Medicines taken by User per Day"
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="28px" h="28px" as={IoBag} color={brandColor} />
                  }
                />
              }
              name="Daily Medicines per User"
              value={chartsData?.data?.data.averageMedicinesUserPerDay}
            />
            <Link
              display={{
                base: "flex",
                md: "none",
                lg: "none",
                xl: "none",
                "2xl": "none",
              }}
              href="https://tidycal.com/ankur-pillup/30-minute-investor-meeting-with-ankur-solanki-co-founder-pillup ">
              <Button
                display={{
                  base: "flex",
                  md: "none",
                  lg: "none",
                  xl: "none",
                  "2xl": "none",
                }}
                colorScheme="yellow"
                variant="outline"
                width={"full"}
                py={"25px"}>
                Schedule Investor Meeting{" "}
              </Button>
            </Link>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 1, xl: 2 }}
            mt="20px"
            gap="20px"
            mb="20px">
            <TotalSpent {...chartsData?.data?.data} />
            {/* new order reorder ratio graph */}
            <OrderReorderStats {...chartsData?.data?.data} name="" />
          </SimpleGrid>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr", lg: "1fr 1fr" }}
            gap="20px">
            {/* <GridItem colSpan={{ base: 1, md: 1 }}> */}
            {/* <OrderReorderStats {...chartsData?.data?.data} name="revenue" /> */}
            {/* <DailyTraffic {...chartsData?.data?.data} /> */}
            {/* </GridItem> */}
            <GridItem colSpan={{ base: 1, md: 1 }}>
              <Conversion {...chartsData?.data?.data} />
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 1 }}>
              <WeeklyRevenue {...chartsData?.data?.data} />
            </GridItem>
          </Grid>
          {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
        </Box>
      </>
    );
  } catch (err) {
    console.log(err);
  }
}
