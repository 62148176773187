// data: {
//   name: "Revenue",
//   values: [50, 64, 48, 66, 49, 68],
//   headers: ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"]
// }
const generateRevenueChart = (data) => {
  // let { name, values, headers } = data;
  let RevenueChart = {
    name: data?.name,
    data: data?.values,
  };
  let RevenueChartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 5,
        opacity: 0.1,
        color: "#fe4b9c",
      },
    },
    colors: ["#fe4b9c", "#fe4b9c"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#fe4b9c",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "numeric",
      categories: data?.headers,
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
    },
    legend: {
      show: true,
    },
    grid: {
      show: false,

      column: {
        color: ["#fe4b9c"],
        opacity: 0.2,
      },
    },
    color: ["#000"],
  };
  return { RevenueChart, RevenueChartOptions };
};

// data: {
//   name: ["New Orders", "Reorders"],
//   newOrders: [50, 64, 48, 66, 49, 68],
//   reorders: [50, 64, 48, 66, 49, 68],
//   headers: ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"]
// }

const generateNewOrdersRevenueChart = (data) => {
  let NewOrdersRevenueChart = [
    {
      name: data?.name[0],
      data: data?.newOrders,
    },
    {
      name: data?.name[1],
      data: data?.reorders,
    },
  ];
  let NewOrdersRevenueChartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 5,
        opacity: 0.1,
        color: "#fe4b9c",
      },
    },
    colors: ["#3e63f2", "#fe4b9c"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#3e63f2",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "numeric",
      categories: data?.headers,
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#b680dd"],
        opacity: 0.5,
      },
    },
    color: ["#7551FF", "#b680dd"],
  };

  return { NewOrdersRevenueChart, NewOrdersRevenueChartOptions };
};

// data: {
//   name: ["New Orders", "Reorders"],
//   newOrders: [50, 64, 48, 66, 49, 68],
//   reorders: [50, 64, 48, 66, 49, 68],
//   headers: ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"]
// }

const generateNewOrdersChart = (data) => {
  let NewOrdersChart = [
    {
      name: data?.name[0],
      data: data?.newOrders,
    },
    {
      name: data?.name[1],
      data: data?.reorders,
    },
  ];
  let NewOrdersChartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 5,
        opacity: 0.1,
        color: "#fe4b9c",
      },
    },
    colors: ["#3e63f2", "#fe4b9c"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#3e63f2",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "numeric",
      categories: data?.headers || ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"],
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#3e63f2"],
        opacity: 0.5,
      },
    },
    color: ["#7551FF", "#3e63f2"],
  };

  return { NewOrdersChart, NewOrdersChartOptions };
};

// data: {
//   name: "Delivery Time",
//   values: [50, 64, 48, 66, 49, 68],
//   headers: ["<1", "1", "2", "3", "4", ">4"]
//  }

const generateDeliveryTimeChart = (data) => {
  let DeliveryTimeChart = [
    {
      name: data?.name,
      data: data?.values,
    },
  ];
  let DeliveryTimeChartOptions = {
    chart: {
      height: "100%",
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: data?.headers,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#32373a",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#32373a",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 0,
      opacity: 0.25,
      yaxis: {
        lines: {
          show: true,
          opacity: 0.25,
        },
      },
      xaxis: {
        lines: {
          opacity: 0.25,
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#3e63f2",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#3e63f2",
              opacity: 0.8,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
  };
  return { DeliveryTimeChart, DeliveryTimeChartOptions };
};

// data: {
//   name: "Discontinuation Tracking",
//   values: [40,5, 50, 5],
//   headers: ["Delivery Time", "Human Error", "Discount", "Courses Completed"]
//  }

const generateDiscontinuationChart = (data) => {
  let DiscontinuationChart = data?.values;
  let DiscontinuationChartOptions = {
    labels: data?.headers,
    colors: ["#101217", "#101217", "#101217", "#101217"],
    chart: {
      width: "200px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    fill: {
      colors: ["#254dff", "#ff9900", "#ff3e25", "#ff1981"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };
  return { DiscontinuationChart, DiscontinuationChartOptions };
};

// data: {
// name: ["B to B", "Referal", "B to C"],
//   type1 : [40, 50, 60, 70, 80, 90, 100,110, 120],
//   type2 : [40, 50, 60, 70, 80, 90, 100,110, 120],
//   type3 : [40, 50, 60, 70, 80, 90, 100,110, 120],
//   headers: ["17", "18", "19", "20", "21", "22", "23", "24", "25"]
// }

const customerAcquisitionChart = (data) => {
  let CustomerAcquisitionChart = [
    {
      name: data?.name[0] || "Type 1",
      data: data?.type1,
    },
    {
      name: data?.name[1] || "Type 2",
      data: data?.type2,
    },
    {
      name: data?.name[2] || "Type 3",
      data: data?.type3,
    },
  ];

  let CustomerAcquisitionChartOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: data?.headers,
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#32373a",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "white",
      labels: {
        show: true,
        style: {
          colors: "#32373a",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },

    grid: {
      show: false,
      yaxis: {
        show: false,
        lines: {
          show: true,
          opacity: 0.2,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#fe4b9c", "#5df673", "#3e63f2"],
    },
    legend: {
      show: false,
    },
    colors: ["#fe4b9c", "#5df673", "#3e63f2"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
  };

  return { CustomerAcquisitionChart, CustomerAcquisitionChartOptions };
};
export {
  generateRevenueChart,
  generateNewOrdersRevenueChart,
  generateNewOrdersChart,
  generateDeliveryTimeChart,
  generateDiscontinuationChart,
  customerAcquisitionChart,
};
