const _ = require("lodash");
// const fs = require("fs");


export const getOrdersNewOrders = (orders) => {
  let allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let permittedMonthData = new Map();
  const patientMap = new Map();

  let monthBack = 1; // how many months of data to we want to display

  let currMonth = new Date().getMonth();
  // start from prev month
  currMonth--;

  let data = {
    months: [],
    newOrders: Array(monthBack).fill(0),
    reOrders: Array(monthBack).fill(0),
    totalOrders: 0,
    monthlyAvgDuration: Array(monthBack).fill(0),
    monthlyTotalDuration: Array(monthBack).fill(0),
    totalDuration: 0,
    avgDuration: 0,
    newOrderRevenue: Array(monthBack).fill(0),
    reOrderRevenue: Array(monthBack).fill(0),
    totalRevenue: 0,
  };
  // let data2 = [{
  //     name: "",
  //     orderStatus: '',
  // }]

  // sort by date function
  const sortedArray = _.sortBy(orders, (o) => o.Date);

  // TODO: USE TIME DELTA FOR THIS LOGIC
  // Add months dynamically and map their indexes
  for (let i = 0; i < monthBack; i++) {
    if (currMonth === 0) {
      currMonth = 12;
    }
    data.months.unshift(allMonths[currMonth]);
    permittedMonthData.set(currMonth, monthBack - i - 1);
    currMonth--;
  }

  for (let i = 0; i < sortedArray.length; i++) {
    // check if the order is deleted or not
    let deletedAt = sortedArray[i].deletedAt;
    if (deletedAt !== "") {
      continue;
    }

    if (sortedArray[i].Duration <= 5) {
      continue;
    }

    let date = sortedArray[i].Date;
    let splitDate = date.split("-");
    // coverts string into number also makes it in 0 indexing
    let monthInThisDate = +splitDate[1] - 1;
    // if this is in considered month?
    if (!permittedMonthData.has(monthInThisDate)) {
      // set this to maintain integrity for new orders and re-orders
      // i.e. patient has ordered so add it into the map regardless of month
      patientMap.set(sortedArray[i].patientId);
      continue;
    }
    //re-order
    if (patientMap.has(sortedArray[i].patientId)) {
      // get prescriptionId => get medicines, getRevenue()
      ++data.reOrders[permittedMonthData.get(monthInThisDate)];
      data.reOrderRevenue[permittedMonthData.get(monthInThisDate)] +=
        +parseFloat(sortedArray[i].revenue).toFixed(2);
      // data2.push({ name: sortedArray[i].patientId, orderStatus: 'Re-Order' })
    } else {
      patientMap.set(sortedArray[i].patientId);
      ++data.newOrders[permittedMonthData.get(monthInThisDate)];
      data.newOrderRevenue[permittedMonthData.get(monthInThisDate)] +=
        +parseFloat(sortedArray[i].revenue).toFixed(2);
      // data2.push({ name: sortedArray[i].patientId, orderStatus: 'New-Order' })
    }
    data.monthlyTotalDuration[permittedMonthData.get(monthInThisDate)] +=
      +sortedArray[i].Duration;
    data.totalDuration += +sortedArray[i].Duration;
    data.totalOrders++;
    data.totalRevenue += +parseFloat(sortedArray[i].revenue).toFixed(2);
  }

  data.avgDuration = +(data.totalDuration / data.totalOrders).toFixed(2);

  for (let i = 0; i < monthBack; i++) {
    data.monthlyAvgDuration[i] = +(
      data.monthlyTotalDuration[i] /
      (data.newOrders[i] + data.reOrders[i])
    ).toFixed(2);
  }

 

  return data;
};;;
