import React from "react";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
// Chakra imports
import {
  Box,
  Button,
  cookieStorageManager,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components

import axios from "axios";
import { useHistory } from "react-router-dom";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";

// Google Login
import { GoogleLogin } from "react-google-login";
import { getBaseURL } from "utils/baseUrl";
import { signIn } from "utils/requests";
import SignUp from "../signup";
import { getApi } from "utils/requests";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  let history = useHistory();

  const googleResponse = async (response) => {
    // store the token in local storage
    if (response || response !== undefined) {
      localStorage.setItem("google-login", response.tokenId);
      localStorage.setItem("name", response?.profileObj?.name);
    } else {
      console.log("Something went wrong");
    }

    const serverResponse = await axios({
      method: "POST",
      url: getApi("api/user/googleLogin"),
      data: { tokenId: localStorage.getItem("google-login") },
    });

    // if the server response is 200 then store the token in cookies

    if (serverResponse.data.success) {
      localStorage.removeItem("google-login");

      if (
        serverResponse.data.data.jwt !== undefined &&
        serverResponse.data.data.jwt !== null
      ) {
        signIn(
          serverResponse.data.data.jwt,
          serverResponse.data.data.newUser,
          serverResponse.data.data.User
        );
        if (serverResponse.data.data.newUser === 1) {
          history.replace("/new/sign-up");

          localStorage.setItem("image", serverResponse.data.data.User.image);
        } else {
          history.replace("/admin");
          localStorage.setItem("image", serverResponse.data.data.User.image);
        }
        // history.replace("/admin");
      } else {
        alert("Didn't get the token");
      }
    } else {
      // alert("Something went wrong");
    }
  };
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {localStorage.getItem("newUser") &&
      localStorage.getItem("newUser") === true ? (
        <SignUp />
      ) : (
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column">
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px" mt="15vh">
              Sign In
            </Heading>
            <Text color={"red"} fontSize="14px">
              Please make sure you've enabled Cookies on your browser
            </Text>
            <Text
              mb="36px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md">
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}>
            <GoogleLogin
              clientId="155712534024-h6b4splkepop58h46dc28fgko6oipsq7.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  fontSize="sm"
                  me="0px"
                  mb="26px"
                  py="15px"
                  h="50px"
                  borderRadius="16px"
                  bg={googleBg}
                  color={googleText}
                  fontWeight="500"
                  _hover={googleHover}
                  _active={googleActive}
                  _focus={googleActive}>
                  <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
                  Sign in with Google
                </Button>
              )}
              buttonText="Login with Google"
              onSuccess={googleResponse}
              onFailure={googleResponse}
              cookiePolicy={"single_host_origin"}
              style={{
                borderRadius: "16px",
                color: googleText,
                textAlign: "center",
              }}
            />
          </Flex>
        </Flex>
      )}
    </DefaultAuth>
  );
}

export default SignIn;
