// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import { generateRevenueChart } from "variables/configureCharts";

export default function TotalSpent (props) {
  const { ...rest } = props;

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w={{
        base: "100vw",
        lg: "100%",
      }}
      mb="0px"
      {...rest}>
      <Text
        me="auto"
        mb={"10px"}
        color={textColor}
        fontSize="lg"
        fontWeight="700"
        lineHeight="100%">
        Revenue
      </Text>

      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Flex
          flexDirection="column"
          justifyContent={"center"}
          me="20px"
          mt="px">
          <Text
            color={textColor}
            fontSize="34px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%">
            ${props?.revenueChart?.growth}
          </Text>
          <Flex align="start" flexDirection={"column"} mb="20px">
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
              me="12px">
              Last Month Growth
            </Text>
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
              <Text color="green.500" fontSize="sm" fontWeight="700">
                ${props?.revenueChart?.monthGrowth}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {props && props.revenueChart && (
          <Box minH="260px" minW="75%" mt="auto">
            <LineChart
              chartData={[
                generateRevenueChart(props?.revenueChart).RevenueChart,
              ]}
              chartOptions={
                generateRevenueChart(props?.revenueChart).RevenueChartOptions
              }
            />
          </Box>
        )}
      </Flex>
      <Flex justifyContent={"center"}>
        <Flex alignItems={"center"} gap="10px">
          <Box w={"15px"} h={"15px"} bg={"#db4288"}></Box>
          <h1>
            {generateRevenueChart(props?.revenueChart).RevenueChart?.name}
          </h1>
        </Flex>
      </Flex>
    </Card>
  );
}
