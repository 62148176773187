// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import { orderNewOrderStatsLineChartOptions } from "variables/charts";
import { orderNewOrderStatsLineChart } from "variables/charts";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import { generateNewOrdersChart } from "variables/configureCharts";
import { generateNewOrdersRevenueChart } from "variables/configureCharts";

export default function OrderReorderStats (props) {
  const { ...rest } = props;

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <Card
      justifyContent="space-between"
      align="center"
      direction="column"
      w={{
        base: "100vw",
        lg: "100%",
      }}
      mb="0px"
      h="100%"
      {...rest}>
      <Text
        me="auto"
        mb={"10px"}
        color={textColor}
        fontSize="lg"
        fontWeight="700"
        lineHeight="100%">
        {props.name === "revenue"
          ? "Revenue by New Orders / Reorders"
          : "New Orders / Reorders"}
      </Text>

      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        {props.name !== "revenue" && (
          <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
            <Flex
              flexDirection="column"
              justifyContent={"center"}
              align="start"
              mt="40px">
              <Flex align="start" flexDirection={"column"} mb="20px">
                <Text
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                  mt="4px"
                  align={"start"}
                  me="12px">
                  Orders Growth
                </Text>
                <Flex align="center">
                  <Icon
                    as={RiArrowUpSFill}
                    color="green.500"
                    me="2px"
                    mt="2px"
                  />
                  <Text color="green.500" fontSize="sm" fontWeight="700">
                    {props?.newReorderChart?.orderGrowth}
                  </Text>
                </Flex>
              </Flex>
              <Flex align="start" flexDirection={"column"} mb="20px">
                <Text
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                  align={"start"}
                  me="12px">
                  New Orders Growth
                </Text>
                <Flex align="center">
                  <Icon
                    as={RiArrowUpSFill}
                    color="green.500"
                    me="2px"
                    mt="2px"
                  />
                  <Text color="green.500" fontSize="sm" fontWeight="700">
                    {props?.newReorderChart?.newOrderGrowth}
                  </Text>
                </Flex>
              </Flex>
              <Flex align="start" flexDirection={"column"} mb="20px">
                <Text
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                  align={"start"}
                  me="12px">
                  ReOrders Growth
                </Text>
                <Flex align="center">
                  <Icon as={RiArrowUpSFill} color="green.500" me="2px" />
                  <Text color="green.500" fontSize="sm" fontWeight="700">
                    {props?.newReorderChart?.reorderGrowth}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
        {props.name === "revenue" ? (
          <>
            {props && props.newReordersChartRevenue && (
              <Box minH="260px" minW="100%" mt="auto">
                <LineChart
                  chartData={[
                    ...generateNewOrdersRevenueChart(
                      props?.newReordersChartRevenue
                    ).NewOrdersRevenueChart,
                  ]}
                  chartOptions={
                    generateNewOrdersRevenueChart(
                      props?.newReordersChartRevenue
                    ).NewOrdersRevenueChartOptions
                  }
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {props && props.newReorderChart && (
              <Box minH="260px" minW="80%" mt="auto">
                <LineChart
                  chartData={[
                    ...generateNewOrdersChart(props?.newReorderChart)
                      .NewOrdersChart,
                  ]}
                  chartOptions={
                    generateNewOrdersChart(props?.newReorderChart)
                      .NewOrdersChartOptions
                  }
                />
              </Box>
            )}
          </>
        )}
      </Flex>
      {props.name === "revenue" ? (
        <Flex justifyContent={"center"}>
          <Flex alignItems={"center"} gap="10px">
            <Box w={"15px"} h={"15px"} bg={"#3e63f2"}></Box>
            <h1>
              {
                generateNewOrdersRevenueChart(props?.newReordersChartRevenue)
                  .NewOrdersRevenueChart[0]?.name
              }
            </h1>
          </Flex>
          <Flex alignItems={"center"} gap="10px">
            <Box w={"15px"} h={"15px"} ml={"30px"} bg={"#fe4b9c"}></Box>
            <h1>
              {
                generateNewOrdersRevenueChart(props?.newReordersChartRevenue)
                  .NewOrdersRevenueChart[1]?.name
              }
            </h1>
          </Flex>
        </Flex>
      ) : (
        <Flex justifyContent={"center"}>
          <Flex alignItems={"center"} gap="10px">
            <Box w={"15px"} h={"15px"} bg={"#3e63f2"}></Box>
            <h1>
              {
                generateNewOrdersChart(props?.newReorderChart).NewOrdersChart[0]
                  .name
              }
            </h1>
          </Flex>
          <Flex alignItems={"center"} gap="10px">
            <Box w={"15px"} h={"15px"} ml={"30px"} bg={"#fe4b9c"}></Box>
            <h1>
              {
                generateNewOrdersChart(props?.newReorderChart).NewOrdersChart[1]
                  .name
              }
            </h1>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
