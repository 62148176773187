export const getBaseURL = (env) => {
  switch (env) {
    case "DEV":
      return `http://localhost:5000`;
    case "PROD":
      return `https://investorapi.pillupreminder.com`;
    default:
      return alert("Invalid Environment");
  }
};
