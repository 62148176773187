import { useQuery } from "react-query";

import { request } from "utils/axiosRequest";
import { getBaseURL } from "utils/baseUrl";

export default function GetDashBoardStatistics () {
  let apiResponse = useQuery("dashboard-stats", () =>
    request({
      url: `${getBaseURL("PROD")}/api/data`,
      method: "GET",
    }), 
  );

  return apiResponse;
}
