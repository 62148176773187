// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import pillupLogo from "assets/img/apple-touch-icon.png";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import GetDashBoardStatistics from "hooks/GetDashBoardStatistics";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, message, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue("#000000", "#000000");
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const { data: DashboardStats } = GetDashBoardStatistics();

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right="0"
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top="0px"
      w={{
        base: "100vw",
        md: "100vw",
        lg: "100vw",
        xl: "100vw",
        "2xl": "100vw",
      }}>
      <Flex
        w="98vw"
        flexDirection={{
          // sm: "column",
          md: "row",
        }}
        justifyContent="space-between"
        margin={"auto"}
        alignItems={{ xl: "center", md: "center" }}>
        <Box
          mb={{ sm: "8px", md: "0px" }}
          display={{
            base: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          }}
          alignItems="center"
          gap={"20px"}>
          {/* Here we create navbar brand, based on route name */}

          <Link
            color={mainText}
            bg="inherit"
            display={"flex"}
            alignItems="center"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize={{ base: "1rem", md: "1.5rem", lg: "1.5rem" }}
            _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}>
            <img src={pillupLogo} alt="pillupLogo" width="50" height="50" />
            Pillup Dashboard [Last Updated :{"  "}
            {DashboardStats?.data?.data?.lastUpdated}]
          </Link>
          <Link href="https://tidycal.com/ankur-pillup/30-minute-investor-meeting-with-ankur-solanki-co-founder-pillup ">
            <Button
              colorScheme="yellow"
              variant="outline"
              display={{
                base: "none",
                md: "block",
                lg: "block",
                xl: "block",
              }}>
              {" "}
              Schedule Investor Meeting{" "}
            </Button>
          </Link>
        </Box>

        <Box
          mb={{ sm: "8px", md: "0px" }}
          flexGrow={1}
          display={{
            md: "none",
            lg: "none",
            xl: "none",
          }}>
          <Box display={"flex"}>
            <img src={pillupLogo} alt="pillupLogo" width="50" height="50" />
            <Text>Pillup Dashboard</Text>
          </Box>
        </Box>

        <Box
          ms="auto"
          w={{ sm: "100%", md: "unset" }}
          display="flex"
          justifyContent={"flex-end"}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
          />
        </Box>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
